ul {
	margin: 0 !important;
	list-style: none !important;
}
.product-pagination {
	.pagination {
		margin-bottom: 0 !important;
	}
}
.card.m-b-30.mt-5 {
	margin-top: 80px !important;
	box-shadow: 0 0 2px 0 rgb(0 0 0 / 30%), 0 2px 3px 0 rgb(0 0 0 / 30%);
}
.card-header {
	padding: 9px 15px;
	background: #f1f1f1;
}
nav.datatablePagination {
	ul {
		display: flex;
		list-style: none;
		padding: 0;
		li {
			padding: 1px 5px;
			background: #b3b3b3;
			margin-right: 1px;
			border-radius: 2px;
		}
		li.active {
			background: #29bbe3;
			color: #fff;
		}
	}
	margin-top: 10px;
	float: right;
	clear: both;
}
.vue-input-tag-wrapper {
	.input-tag {
		border: none;
		background: #7a6fbe;
		color: #fff;
		.remove {
			color: #a74b4b;
		}
	}
}
img.table_product_thumbnail {
	height: 50px !important;
}
.msl-searchable-list__item {
	padding: 0px 8px !important;
}
.vdp-datepicker {
	.form-control {
		&:disabled {
			background-color: #fff !important;
		}
	}
}
.form-control[readonly] {
	background-color: #fff !important;
}
.card-body {
	position: relative;
	.vue-loaders {
		position: absolute;
		left: 50%;
		z-index: 9999999999999999;
	}
}
.lessOpacity {
	opacity: 0.5 !important;
}
div#sidebar-menu {
	ul {
		li {
			ul.submenu {
				li {
					a {
						i {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}
.videoCard {
	box-shadow: 0 0 20px 0 #2b3a4a52;
	padding: 20px;
	margin-bottom: 20px;
}
.videoIframe {
	iframe {
		width: 100% !important;
		height: 500px !important;
	}
}
.topbar {
	.topbar-left {
		background: #003541 !important;
	}
}
.collection-wrapper {
    position: relative;
}
.vue-loaders {
    position: absolute;
    left: 50%;
}
.side-menu {
	background: #003541;
}
.submenu {
	li {
		a {
			&:hover {
				background-color: #002d2e !important;
			}
		}
	}
}
.v-toast {
	.v-toast__item--success {
		p {
			color: #fff !important;
		}
	}
}
ul.review-section li .media img {
    width: 50px;
    margin-right: 10px;
    border-radius: 40px;
}
ul.review-section li {
    display: block;
    margin: 30px 0px;
}

.card {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 0.10rem;
}
.card-header {
	&:first-child {
		border-radius: calc(0.37rem - 1px) calc(0.37rem - 1px) 0 0;
	}
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: #fff;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.track {
	position: relative;
	background-color: #ddd;
	height: 7px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 60px;
	margin-top: 50px;
	.step {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		width: 25%;
		margin-top: -18px;
		text-align: center;
		position: relative;
		&::before {
			height: 7px;
			position: absolute;
			content: "";
			width: 100%;
			left: 0;
			top: 18px;
		}
	}
	.step.active {
		&:before {
			background: #FF5722;
		}
		.icon {
			background: #ee5435;
			color: #fff;
		}
		.text {
			font-weight: 400;
			color: #000;
		}
	}
	.icon {
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 40px;
		position: relative;
		border-radius: 100%;
		background: #ddd;
	}
	.text {
		display: block;
		margin-top: 7px;
	}
}
.itemside {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	.aside {
		position: relative;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.info {
		padding-left: 15px;
		padding-right: 7px;
	}
	.title {
		display: block;
		margin-bottom: 5px;
		color: #212529;
	}
}
.img-sm {
	width: 80px;
	height: 80px;
	padding: 7px;
}
ul.row {
	list-style: none;
	padding: 0;
}
ul.row-sm {
	list-style: none;
	padding: 0;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
.btn-warning {
	color: #ffffff;
	background-color: #ee5435;
	border-color: #ee5435;
	border-radius: 1px;
	&:hover {
		color: #ffffff;
		background-color: #ff2b00;
		border-color: #ff2b00;
		border-radius: 1px;
	}
}

.payment-card-bottom ul li img {
    height: 28px;
}